export interface OpenSigningSessionResponse {
  signatureToken: string;
  signatureTokenExpiration: string;
  prefillData: Record<string, unknown>;
  contextData: Record<string, unknown>;
  returnUrl: string;
  imageUrl: string;
}

export enum OpenSessionError {
  ALREADY_SIGNED = 'already_signed',
  INVALID_STATUS = 'invalid_status',
  EXPIRED = 'expired',
  VOIDED = 'voided',
}
