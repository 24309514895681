import { Field, FieldProps } from "formik";
import ValidatedCheckbox from './validated-checkbox';


const ConnectedValidatedCheckbox = (props: {
    name: string;
    label?: React.ReactNode;
}) => {
    return (<Field name={props.name}>
        {({
            field, // { name, value, onChange, onBlur }
            meta,
        }: FieldProps<boolean>) => (
            <ValidatedCheckbox
                invalid={meta.touched && !!meta.error}
                label={props.label}
                {...field}
            />
        )}
    </Field>);
}

export default ConnectedValidatedCheckbox;