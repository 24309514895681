import axios from 'axios';
import { useState, useEffect } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export const useProfile = (profileId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    setIsLoading(true);

    const doWork = async () => {
      try {
        const response = await axios.get(`api/profiles/${profileId}`);

        if (response.data.imageUrl) {
          setImageUrl(response.data.imageUrl);
        }
      } catch (e) {
        const err = e as Error;
        appInsights.trackException({ exception: err });
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          setError('Profile not found');
        } else {
          setError('An unknown error occured');
        }
      } finally {
        setIsLoading(false);
      }
    };

    doWork();
  }, [profileId, appInsights]);

  return {
    isLoading,
    error: error,
    imageUrl,
  };
};
