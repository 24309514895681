/**
 * Format phone number to match the expected input form and validation
 */
export const formatPhoneNumber = (number: string): string => {
  // check if the current value looks like phone number (from autofill)
  // like 1234567890 so we can format it like the mask
  // first - extract the numerical digits from the string in case it has some different formatting
  const cleanedCurrent =
    number && number.length > 0 ? number.replace(/\D+/g, '') : '';
  // second - check if we have exactly 10 digits
  if (cleanedCurrent.length === 10) {
    // looks like an unformatted phone number so reformat it to match the mask
    return `(${cleanedCurrent.substring(0, 3)}) ${cleanedCurrent.substring(
      3,
      6
    )}-${cleanedCurrent.substring(6, 10)}`;
  }

  return number;
};
