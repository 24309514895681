import React from 'react';

import FullPageMessage from './common/full-page-message';

export const NotFound = () => (
  <FullPageMessage title="Page Not Found">
    <p>We were unable to find the requested page.</p>
    <p>
      If you reached this page from a request to sign a Scope of Appointment
      please try following the link in the original communication again to open
      the document for signing.
    </p>
  </FullPageMessage>
);
