import React from 'react';

const ConditionalSignatureBlockHeader = (props: { isTelephonic?: boolean }) => {
    return (
        <div>
            <p>
                {!props.isTelephonic ? (
                    "Please enter your name below and submit this form to indicate your agreement:"

                ) : (
                    "Please enter your name below and submit this form to indicate you have accurately captured the client's responses:"
                )}
            </p>
        </div>
    );
};

export default ConditionalSignatureBlockHeader;