import React from 'react';
import TelephonicScript from './telephonic-script';

const TelephonicIntroScript = () => (
    <TelephonicScript>
        <p>
            The Centers for Medicare and Medicaid Services and other governing bodies
            require agents to document the scope of a marketing appointment 48 hours
            prior to any sales meeting to ensure your understanding of what will be
            discussed between the agent and you, the Medicare beneficiary. All
            information provided is confidential.
        </p>
        <p>
            Please confirm by saying yes or no to the following types of products that
            you would like to discuss during your consultation with our agent. I can
            give a definition of each type of product if you are not familiar with it,
            please let me know. Please note the person who will discuss the products
            is either employed or contracted by a Medicare plan. Our agents do not
            work directly for the Federal government. Our agency may also be paid
            based on your enrollment in a plan. By giving your verbal consent over the
            phone today, it does not obligate you to enroll in a plan, affect your
            current or future enrollment status, or enroll you in a Medicare plan.
            Scope of Appointment documentation is subject to CMS record retention
            requirements.
        </p>
        <p>
            Respond yes or no to the following question. Would you like to discuss:
        </p>
    </TelephonicScript>
);

export default TelephonicIntroScript;
