import React from 'react';
import { useField } from 'formik';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const ConnectedDatePicker = (props: {
  name: string;
  // All other props
  [x: string]: unknown;
}) => {
  const [field, meta, helpers] = useField<Date>(props.name);

  return <DatePicker
    {...props}
    className={meta.touched && !!meta.error ? 'is-invalid form-control' : 'form-control'}
    selected={field.value}
    onChange={(newVal: Date) => helpers.setValue(newVal)}
    onBlur={field.onBlur}
  />
};
