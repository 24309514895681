import React from 'react';
import { Input } from 'reactstrap';
import { Field, FieldProps } from 'formik';

export const ConnectedInput = (props: {
  name: string;
  // All other props
  [x: string]: unknown;
}) => (
  <Field name={props.name}>
    {({
      field, // { name, value, onChange, onBlur }
      meta,
    }: FieldProps<string>) => (
      <Input
        {...Object.assign({}, props, field)}
        invalid={meta.touched && !!meta.error}
      />
    )}
  </Field>
);
