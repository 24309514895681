import React from 'react';

const AgreementClause = () => (
  <p>
    <strong>
      By signing the form, you agree to a meeting with a sales agent to discuss
      the types of products you marked above.
    </strong>
    &nbsp;Please note, the person who will discuss the products is either
    employed or contracted by a Medicare plan or a health plan. &nbsp;They{' '}
    <u>do not</u> work directly for the Federal government. &nbsp;This
    individual may also be paid based on your enrollment in a plan. &nbsp;
    <strong>
      Signing this form does NOT obligate you to enroll in a plan, affect your
      current or future enrollment status, enroll you in a health plan on or off
      the exchange, or enroll you in a Medicare plan. Scope of Appointment
      documentation is subject to CMS and other governing entities record
      retention requirements.
    </strong>
  </p>
);

export default AgreementClause;
