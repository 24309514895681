import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

const YesNoSelectionDisplay = (props: { title: string; value: boolean }) => {
  return (
    <div className="yes-no-selection-display">
      <span className="icon">
        {props.value ? <FaCheck color="green" /> : <FaTimes color="red" />}
      </span>
      <span>{props.title}</span>
    </div>
  );
};

export default YesNoSelectionDisplay;
