import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  OpenSessionError,
  OpenSigningSessionResponse,
} from 'models/api/signing-session';

const unknownErrorText = 'An unknown error occured';

const determineErrorText = (e: Error | Record<string, unknown>): string => {
  if (!axios.isAxiosError(e)) return unknownErrorText;
  const axiosErr = e as AxiosError<Record<string, unknown>>;
  if (!axiosErr.response) return unknownErrorText;

  if (axiosErr.response.status === 409) {
    const conflictReason = axiosErr.response.data.reason;
    if (conflictReason === OpenSessionError.ALREADY_SIGNED) {
      return 'This scope of appointment has already been completed';
    } else if (conflictReason === OpenSessionError.INVALID_STATUS) {
      return 'This scope cannot be signed';
    } else if (conflictReason === OpenSessionError.EXPIRED) {
      return 'Your signing link has expired';
    } else if (conflictReason === OpenSessionError.VOIDED) {
      return 'This scope of appointment request was cancelled';
    } else {
      return unknownErrorText;
    }
  } else if (axiosErr.response.status === 404) {
    return 'Scope of Appointment not found';
  } else {
    return unknownErrorText;
  }
};

export const useSigningSession = (
  requestId: string,
  role: string,
  token: string
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState<string | undefined>();
  const [returnUrl, setReturnUrl] = useState<string | undefined>();
  const [signatureToken, setSignatureToken] = useState<string | undefined>();
  const [contextData, setContextData] = useState<Record<string, unknown>>({});
  const [prefillData, setPrefillData] = useState<
    Record<string, unknown> | undefined
  >({});
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const appInsights = useAppInsightsContext();

  // open signing session
  useEffect(() => {
    const doWork = async () => {
      try {
        const response = await axios.post<OpenSigningSessionResponse>(
          `api/request/${requestId}/${role}`,
          {
            token,
          }
        );

        setSignatureToken(response.data.signatureToken);
        setContextData(response.data.contextData || {});
        setPrefillData(response.data.prefillData);

        if (response.data.imageUrl && response.data.imageUrl.length) {
          setImageUrl(response.data.imageUrl);
        }

        if (response.data.returnUrl && response.data.returnUrl.length) {
          setReturnUrl(response.data.returnUrl);
        }
      } catch (e) {
        const err = e as Error;
        appInsights.trackException({ exception: err });
        setErrorText(determineErrorText(err));
      } finally {
        setIsLoading(false);
      }
    };

    doWork();
  }, [requestId, role, token, appInsights]);

  // wrap up submission function with the signatureToken and
  // returnUrl known for this signing session
  const doSubmission = async (data: Record<string, unknown>) => {
    await axios.post(`api/request/${requestId}/${role}/responses`, {
      token,
      signatureToken: signatureToken,
      responses: data,
    });
    return returnUrl;
  };

  return {
    isLoading,
    error: errorText,
    contextData,
    prefillData,
    imageUrl,
    submitFormResponses: doSubmission,
  };
};
