import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { BsExclamationCircle } from 'react-icons/bs';

const YesNoSwitch = (props: {
  invalid?: boolean;
  onChange?: (newValue: boolean) => void;
  value: boolean | undefined;
}) => {
  const buttonColor = props.invalid === true ? 'danger' : undefined;

  const toggleButtonForTarget = (targetValue: boolean, text: string) => (
    <Button
      color={buttonColor}
      outline={props.value !== targetValue}
      onClick={() => props.onChange && props.onChange(targetValue)}
      active={props.value === targetValue}
    >
      {text}
    </Button>
  );

  return (
    <div className="yes-no-switch">
      <ButtonGroup>
        {toggleButtonForTarget(true, 'Yes')}
        {toggleButtonForTarget(false, 'No')}
      </ButtonGroup>
      {/* Always include invalid icon but hide it so we don't shift around buttons */}
      <BsExclamationCircle
        size="1.5em"
        display={props.invalid ? '' : 'none'}
        className="invalid-input-icon"
      />
    </div>
  );
};

export default YesNoSwitch;
