import React from 'react';
import { Container, Row } from 'reactstrap';

import BrandingLogo from 'components/common/branding-logo';
import 'components/common/SoaForm.css';
import { BeneficiaryResponses } from 'models/beneficiary';
import { RecursivePartial } from 'models/common';

import BeneficiaryForm from './beneficiary-form';
import BeneficiaryHeading from './beneficiary-heading';
import ConditionalIntroBlock from './conditional-intro';

export const BeneficiaryView = (props: {
  onSubmit: (data: BeneficiaryResponses) => Promise<void>;
  initialValues: RecursivePartial<BeneficiaryResponses>;
  logoUrl?: string;
  isTelephonic: boolean;
}) => (
  <Container>
    <Row className="align-items-center justify-content-center">
      {props.logoUrl && <BrandingLogo imageUrl={props.logoUrl} />}
    </Row>
    <BeneficiaryHeading isTelephonic={props.isTelephonic} />
    <ConditionalIntroBlock isTelephonic={props.isTelephonic} />
    <BeneficiaryForm
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      isTelephonic={props.isTelephonic}
    />
  </Container>
);
