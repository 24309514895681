import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { Col, Container, Row } from 'reactstrap';
import { BsExclamationCircle } from 'react-icons/bs';

import { useHasInvalidInput } from 'helpers/hooks/form-validity';

const FormValidationSummary = <Values extends unknown>(props: {
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}) => {
  const hasInvalidInput = useHasInvalidInput(props.errors, props.touched);

  // when no inputs are being flagged as invalid we want to hide this validation summary message
  if (!hasInvalidInput) return null;

  return (
    <Container>
      <Row className="align-items-center">
        <Col md="auto" xs="auto">
          <div className="validation-warning-message align-middle">
            Missing required fields. Please check for red fields with the error
            icon.
            <span>
              <BsExclamationCircle
                className="invalid-input-icon"
                size="1.5em"
              />
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FormValidationSummary;
