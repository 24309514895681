import React from 'react';

const BrandingLogo = (props: { imageUrl: string }) => {
  return (
    <img
      src={props.imageUrl}
      alt="organization logo"
      className="branding-logo"
    />
  );
};

export default BrandingLogo;
