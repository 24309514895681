import React from 'react';
import { Col, Row } from 'reactstrap';

import { ConnectedInputGroup } from 'components/common/connected-input-group';
import ConnectedPhoneInput from 'components/common/connected-phone-input';
import CopyBeneficiaryContactButton from './copy-beneficiary-contact-button';

interface BeneficiaryInfoProps {
  namePrefix: string;
  onAddressPrefill?(): void;
}

const BeneficiaryInfo = (props: BeneficiaryInfoProps) => (
  <div>
    <ConnectedInputGroup
      type="text"
      name={`${props.namePrefix}firstName`}
      label="First Name"
    />
    <ConnectedInputGroup
      type="text"
      name={`${props.namePrefix}lastName`}
      label="Last Name"
    />
    {props.onAddressPrefill && <div style={{ paddingBottom: 15 }}><CopyBeneficiaryContactButton onClick={props.onAddressPrefill} /></div>}
    <ConnectedInputGroup
      type="text"
      name={`${props.namePrefix}address1`}
      label="Address"
    />
    <ConnectedInputGroup
      type="text"
      name={`${props.namePrefix}address2`}
      label="Address 2"
    />

    <Row>
      <Col md={6}>
        <ConnectedInputGroup
          type="text"
          name={`${props.namePrefix}city`}
          label="City"
        />
      </Col>
      <Col md={4}>
        <ConnectedInputGroup
          type="text"
          maxLength={2}
          name={`${props.namePrefix}state`}
          label="State"
        />
      </Col>
      <Col md={2}>
        <ConnectedInputGroup
          type="text"
          name={`${props.namePrefix}zip`}
          label="Zip"
        />
      </Col>
    </Row>
    <ConnectedPhoneInput
      name={`${props.namePrefix}phoneNumber`}
      label="Phone Number"
      groupClassName="w-25"
      groupStyle={{
        minWidth: '150px'
      }}
    />
  </div>
);

export default BeneficiaryInfo;
