import React from 'react';

const BeneficiaryHeading = (props: { isTelephonic: boolean }) => {
    return (
        <div>
            <h1>{props.isTelephonic ? 'Telephonic ' : null}Scope of Sales</h1>
            <h2>Appointment Confirmation Form</h2>
        </div>
    );
};

export default BeneficiaryHeading;