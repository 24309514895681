import React, { useEffect, useRef } from 'react';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { ConnectedInputGroup } from 'components/common/connected-input-group';
import LoadingOverlay from 'components/common/loading-overlay';
import SubmitButton from 'components/common/submit-button';
import {
  RequestFormValues,
  RequestFormValuesWithCaptcha,
} from 'api/request-start';

export const InitialValues: RequestFormValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const RequestStartSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
});

const useRecaptchaSiteKey = () => {
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    if (!siteKey) {
      toast.error('Recaptcha is not properly set up');
    }
  }, [siteKey]);

  return siteKey || 'unknown';
};

const RequestStartForm = (props: {
  onSubmit: (values: RequestFormValuesWithCaptcha) => Promise<void>;
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey = useRecaptchaSiteKey();
  const appInsights = useAppInsightsContext();

  const handleSubmit = async (vals: RequestFormValues) => {
    try {
      if (recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync();
        await props.onSubmit({
          ...vals,
          'g-recaptcha-response': token || '',
        });
      } else {
        throw new Error('No recaptcha reference');
      }
    } catch (e) {
      appInsights.trackException({ exception: e as Error });
      console.error(e);
      toast.error('There was an error submitting your responses.');
    }
  };

  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={handleSubmit}
      validationSchema={RequestStartSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <ConnectedInputGroup
            type="text"
            name="firstName"
            label="First Name"
          />
          <ConnectedInputGroup type="text" name="lastName" label="Last Name" />
          <ConnectedInputGroup type="text" name="email" label="Email Address" />
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} />
          <SubmitButton
            submitting={isSubmitting}
            title="Sign Now"
            color="primary"
          />
          {isSubmitting && <LoadingOverlay text="Submitting..." />}
        </Form>
      )}
    </Formik>
  );
};

export default RequestStartForm;
