import React from 'react';
import { Alert } from 'reactstrap';

const TelephonicScript = (props: {
    children: React.ReactNode;
}) => (
    <Alert color="info" className="telephonic-script">
        {props.children}
    </Alert>
);

export default TelephonicScript;