import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerText = (props: { text: string }) => (
  <div className="spinner-indicator">
    <div className="align-items-center justify-content-center d-flex flex-row">
      <Spinner color="primary" />
      &nbsp;&nbsp;<div className="spinner-indicator-text">{props.text}</div>
    </div>
  </div>
);

export default SpinnerText;
