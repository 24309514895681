import React from 'react';
import { Container, Row } from 'reactstrap';

import 'components/common/SoaForm.css';

import AgentForm from './agent-form';
import BeneficiarySummary from './beneficiary-summary';
import BrandingLogo from 'components/common/branding-logo';
import { SoaFormCard } from 'components/common/soa-form-card';
import { AgentResponses } from 'models/agent';
import { RecursivePartial } from 'models/common';
import { PlanTypeSelections } from 'models/beneficiary';

export const AgentView = (props: {
  onSubmit: (data: AgentResponses) => Promise<void>;
  initialValues: RecursivePartial<AgentResponses>;
  beneficiaryContext: Record<string, unknown>;
  logoUrl?: string;
}) => (
  <Container>
    <Row className="align-items-center justify-content-center">
      {props.logoUrl && <BrandingLogo imageUrl={props.logoUrl} />}
    </Row>
    <h1>Scope of Sales</h1>
    <h2>Appointment Confirmation Form</h2>
    <SoaFormCard title="Beneficiary Summary">
      <BeneficiarySummary
        {...props.beneficiaryContext}
        name={props.beneficiaryContext.name as string}
        phoneNumber={props.beneficiaryContext.phoneNumber as string}
        planTypeSelections={
          props.beneficiaryContext.planTypeSelections as PlanTypeSelections
        }
      />
    </SoaFormCard>
    <AgentForm onSubmit={props.onSubmit} initialValues={props.initialValues} />
  </Container>
);
