import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import App from './app';
import { unregister } from './registerServiceWorker';
import GitInfoMetaData from './git-info-metadata';
import { appInsights, reactPlugin } from './helpers/app-insights';

appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);

root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <BrowserRouter basename={baseUrl || undefined}>
      <GitInfoMetaData>
        <App />
      </GitInfoMetaData>
    </BrowserRouter>
  </AppInsightsContext.Provider>
);

// disable the service worker for now
//registerServiceWorker();
unregister();
