import React from 'react';
import { addMonths, addYears, endOfMonth, startOfYear } from 'date-fns';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import 'components/common/SoaForm.css';
import LoadingOverlay from 'components/common/loading-overlay';
import { SoaFormCard } from 'components/common/soa-form-card';
import SignatureBlock from 'components/common/signature-block';
import ConditionalQuestionRow from 'components/common/conditional-question-row';
import { ConnectedInputGroup } from 'components/common/connected-input-group';
import { ConnectedDatePicker } from 'components/common/connected-date-picker';
import FormValidationSummary from 'components/common/form-validation-summary';
import { formatPhoneNumber } from 'helpers/phone-number-format';
import { telephonePattern } from 'helpers/regex';

import AgentInfo from './agent-info';
import { RecursivePartial } from 'models/common';
import { AgentResponses } from 'models/agent';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  contactMethod: '',
  signedPriorToMeeting: null,
  reasonNotSignedPrior: '',
  plansRepresented: '',
  appointmentDate: null,
  signedName: '',
};

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required').matches(telephonePattern),
  contactMethod: Yup.string().required('Required'),
  signedPriorToMeeting: Yup.bool().required(),
  reasonNotSignedPrior: Yup.string().when('signedPriorToMeeting', ([other], schema) => {
    return other ? schema : schema.required()
  }),
  plansRepresented: Yup.string().required('Required'),
  appointmentDate: Yup.date().required('Required'),
  signedName: Yup.string().required('Required'),
});

function buildInitialValues(prefillData: RecursivePartial<AgentResponses>) {
  prefillData = prefillData || {};

  return Object.assign(
    initialValues,
    prefillData,
    // take phone number from prefill data and format it per what our validation needs
    {
      phoneNumber: formatPhoneNumber(prefillData.phoneNumber as string) || '',
    }
  );
}

const AgentForm = (props: {
  onSubmit: (data: AgentResponses) => Promise<void>;
  initialValues: RecursivePartial<AgentResponses>;
}) => {
  return (
    <Formik<AgentResponses>
      initialValues={buildInitialValues(props.initialValues)}
      onSubmit={props.onSubmit}
      validationSchema={SignupSchema}
    >
      {({ handleSubmit, isSubmitting, values, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <SoaFormCard title="Your Info">
            <AgentInfo />
          </SoaFormCard>
          <SoaFormCard title="Appointment">
            <ConnectedInputGroup
              type="text"
              label="Initial Method of Contact"
              name="contactMethod"
            />
            <ConditionalQuestionRow
              title="Was the SOA documented prior to the meeting?"
              name="signedPriorToMeeting"
            />
            {values.signedPriorToMeeting === false && (
              <ConnectedInputGroup
                type="text"
                label="Reason why SOA was not documented prior to meeting"
                name="reasonNotSignedPrior"
              />
            )}
            <ConnectedInputGroup
              label="Date Appointment Completed"
              name="appointmentDate"
              component={<ConnectedDatePicker
                name="appointmentDate"
                // start of the previous year is the first selectable (in case filling out on Jan 1st for Dec meeting)
                minDate={addYears(startOfYear(new Date()), -1)}

                // end of the current year is the last selectable
                maxDate={addMonths(endOfMonth(new Date()), 1)}

                todayButton="Today"

                // prevent allowing typing input because the component will "Autocorrect" out of range
                // dates and the user may not notice. With this, it will force them to pick a date
                // via the widget.
                onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
              />}
              groupClassName="w-25"
              groupStyle={{
                minWidth: '150px'
              }}
            />
            <ConnectedInputGroup
              type="text"
              label="Plan(s) the agent represented during the meeting"
              name="plansRepresented"
            />
          </SoaFormCard>
          <FormValidationSummary errors={errors} touched={touched} />
          <SignatureBlock isSubmitting={isSubmitting} />
          {isSubmitting && <LoadingOverlay text="Submitting..." />}
        </Form>
      )}
    </Formik>
  );
};

export default AgentForm;
