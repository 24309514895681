import React from 'react';
import { Col, Row, ListGroup, ListGroupItem } from 'reactstrap';

import { PlanTypeSelections } from 'models/beneficiary';
import { PlanTypeData, PlanType } from 'components/common/plan-type-data';
import YesNoSelectionDisplay from 'components/common/yes-no-selection-display';

const InfoRow = (props: { title: string; value: string | JSX.Element }) => (
  <ListGroupItem>
    <Row>
      <Col md="3">
        <strong>{props.title}</strong>
      </Col>
      <Col md="8">{props.value}</Col>
    </Row>
  </ListGroupItem>
);

const SelectedPlanTypes = (props: {
  planTypeSelections: PlanTypeSelections;
}) => {
  const planTypeFields: PlanType[] = Object.keys(
    props.planTypeSelections
  ) as PlanType[];

  return (
    <div>
      {planTypeFields.map((k: PlanType) => (
        <div key={k}>
          <YesNoSelectionDisplay
            title={
              PlanTypeData[k] && PlanTypeData[k].name ? PlanTypeData[k].name : k
            }
            value={props.planTypeSelections[k] === true}
          />
        </div>
      ))}
    </div>
  );
};

const BeneficiarySummary = (props: {
  name?: string;
  phoneNumber?: string;
  planTypeSelections?: PlanTypeSelections;
}) => (
  <ListGroup flush>
    <InfoRow title="Name" value={props.name || 'Unknown'} />
    <InfoRow title="Phone Number" value={props.phoneNumber || 'Unknown'} />
    {props.planTypeSelections && (
      <InfoRow
        title="Product Types Selected"
        value={
          <SelectedPlanTypes planTypeSelections={props.planTypeSelections} />
        }
      />
    )}
  </ListGroup>
);

export default BeneficiarySummary;
