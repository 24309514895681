import React from 'react';

const IntroParagraph = (): JSX.Element => (
  <p>
    The Centers for Medicare and Medicaid Services or other governing bodies
    require agents to document the scope of a marketing appointment prior to any
    face-to-face sales meeting to ensure understanding of what will be discussed
    between the agent and the Medicare beneficiary, health plan customer, or
    their authorized representative. All information provided on this form is
    confidential and should be completed by each person with Medicare, each
    customer reviewing his/her health insurance options, or his/her authorized
    representative.
  </p>
);

export default IntroParagraph;
