import React, { ReactNode } from 'react';
import { CSSProperties } from 'react';
import { FormGroup, Label } from 'reactstrap';

import { ConnectedInput } from './connected-input';

export const ConnectedInputGroup = (props: {
  name: string;
  label: string;
  component?: ReactNode;
  groupClassName?: string;
  groupStyle?: CSSProperties | undefined;
  // All other props
  [x: string]: unknown;
}) => {
  const { groupClassName, groupStyle, name, label, component, ...otherProps } = props;
  return (<FormGroup className={groupClassName} style={groupStyle}>
    <Label for={name}>{label}</Label>
    {component || <ConnectedInput name={name} {...otherProps} />}
  </FormGroup>);
}
