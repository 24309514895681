import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BrandingLogo from 'components/common/branding-logo';

export const CompletedScreen = (props: {
  logoUrl?: string;
  redirecting?: boolean;
}) => {
  return (
    <Container className="text-center">
      <Row className="align-items-center justify-content-center">
        {props.logoUrl && <BrandingLogo imageUrl={props.logoUrl} />}
      </Row>
      <Row className="align-items-center justify-content-center">
        <Col md={12}>
          <h3>Signing Complete</h3>
          {props.redirecting === true ? (
            <p>You will now be redirected back.</p>
          ) : (
            <p>You can safely close this window/tab.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};
