import { FormikErrors, FormikTouched } from 'formik';

export const useHasInvalidInput = <Values>(
  errors: FormikErrors<Values>,
  touched: FormikTouched<Values>
) => {
  // Recursive helper to traverse nested form structure.
  // The built-in Formik isValid prop doesn't quite mirror what we want
  // which is to say we have at least one form input that is currently showing
  // as invalid (touched and has an error).
  // This function looks for any such cases but is smart enough to traverse
  // down nested objects.
  const hasInvalidInput = <Inners>(
    innerErrors: FormikErrors<Inners>,
    innerTouched: FormikTouched<Inners>,
    depth: number
  ): boolean =>
    Object.keys(innerErrors).filter((p) => {
      const fieldName = p as keyof typeof innerErrors;
      if (typeof innerErrors[fieldName] === 'object') {
        // recurse into nested object for validity
        return depth > 0
          ? hasInvalidInput(
              (innerErrors[fieldName] || {}) as FormikErrors<unknown>,
              (innerTouched[fieldName] || {}) as FormikTouched<unknown>,
              depth - 1
            )
          : true; // max depth reached, stop recursing and just call it invalid already
      } else {
        return !!innerErrors[fieldName] && innerTouched[fieldName];
      }
    }).length > 0;

  return hasInvalidInput(errors, touched, 5);
};
