import React from 'react';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import 'components/common/SoaForm.css';
import { SoaFormCard } from 'components/common/soa-form-card';
import { telephonePattern } from 'helpers/regex';

import AgreementClause from './agreement-clause';
import BeneficiaryInfo from './beneficiary-info';
import PlanTypeSelection from './plan-type-selections';
import SignatureBlock from 'components/common/signature-block';
import ConditionalQuestionRow from 'components/common/conditional-question-row';
import { ConnectedInputGroup } from 'components/common/connected-input-group';
import FormValidationSummary from 'components/common/form-validation-summary';
import LoadingOverlay from 'components/common/loading-overlay';
import { formatPhoneNumber } from 'helpers/phone-number-format';
import { BeneficiaryResponses } from 'models/beneficiary';
import { RecursivePartial } from 'models/common';
import { updateValuesWithContactFromBeneficiary } from 'helpers/beneficiary-responses-helpers';
import TelephonicAttestation from './telephonic-attestation';

const PersonInfoShape = {
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string().optional(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required').length(2, 'Must be two characters'),
  zip: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required').matches(telephonePattern),
};

const SignupSchema = (isTelephonic: boolean) => Yup.object().shape({
  planTypeSelections: Yup.object()
    .shape({
      pdp: Yup.bool().required(),
      ma: Yup.bool().required(),
      dentalVisionHearing: Yup.bool().required(),
      supplementalHealth: Yup.bool().required(),
      ms: Yup.bool().required(),
      aca: Yup.bool().required(),
    })
    .required(),
  beneficiary: Yup.object().shape(PersonInfoShape).required(),
  isAuthorizedRep: Yup.bool().required(),
  authorizedRepRelationship: Yup.string().when('isAuthorizedRep', ([other], schema) => {
    return other ? schema.required('Required') : schema
  }),
  authorizedRepresentative: Yup.object().when('isAuthorizedRep', ([other], schema) => {
    return other ? schema.shape(PersonInfoShape).required() : schema
  }),
  signedName: Yup.string().required('Required'),
  telephonicScopeConfirmation: isTelephonic ? Yup.bool().oneOf([true]) : Yup.bool().notRequired()
});

function buildInitialValues(
  prefillData: RecursivePartial<BeneficiaryResponses>
) {
  prefillData = prefillData || {};

  return {
    planTypeSelections: Object.assign(
      {
        pdp: true,
        ma: true,
        dentalVisionHearing: true,
        supplementalHealth: true,
        ms: true,
        aca: true,
      },
      prefillData.planTypeSelections
    ),
    beneficiary: Object.assign(
      {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
      },
      prefillData.beneficiary,
      {
        phoneNumber:
          prefillData.beneficiary && prefillData.beneficiary.phoneNumber
            ? formatPhoneNumber(prefillData.beneficiary.phoneNumber)
            : '',
      }
    ),
    isAuthorizedRep: prefillData.isAuthorizedRep || null,
    authorizedRepRelationship: prefillData.authorizedRepRelationship || '',
    authorizedRepresentative: Object.assign(
      {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phoneNumber: '',
      },
      prefillData.authorizedRepresentative,
      // take phone number from prefill data and format it per what our validation needs
      {
        phoneNumber:
          prefillData.authorizedRepresentative &&
            prefillData.authorizedRepresentative.phoneNumber
            ? formatPhoneNumber(
              prefillData.authorizedRepresentative.phoneNumber
            )
            : '',
      }
    ),
    signedName: prefillData.signedName || '',
    telephonicScopeConfirmation: prefillData.telephonicScopeConfirmation || false
  };
}

const BeneficiaryForm = (props: {
  onSubmit: (data: BeneficiaryResponses) => Promise<void>;
  initialValues: RecursivePartial<BeneficiaryResponses>;
  isTelephonic: boolean;
}) => {
  return (
    <Formik<BeneficiaryResponses>
      initialValues={buildInitialValues(props.initialValues)}
      onSubmit={props.onSubmit}
      validationSchema={() => { return SignupSchema(props.isTelephonic) }}
    >
      {({ handleSubmit, isSubmitting, values, errors, touched, setValues }) => (
        <Form onSubmit={handleSubmit} autoComplete='off'>
          <SoaFormCard title="Please indicate the type of product(s) you want the agent to discuss">
            <PlanTypeSelection namePrefix="planTypeSelections." />
          </SoaFormCard>
          <SoaFormCard title="Beneficiary Info">
            <BeneficiaryInfo namePrefix="beneficiary." />
          </SoaFormCard>
          <ConditionalQuestionRow
            title="Are you an authorized representative signing on behalf of the beneficiary?"
            name="isAuthorizedRep"
          />
          {values.isAuthorizedRep === true && (
            <SoaFormCard title="Authorized Representative Info">
              <ConnectedInputGroup
                type="text"
                name="authorizedRepRelationship"
                label="Relationship to Beneficiary"
              />
              <BeneficiaryInfo namePrefix="authorizedRepresentative." onAddressPrefill={() => setValues(updateValuesWithContactFromBeneficiary(values))} />
            </SoaFormCard>
          )}
          <br />
          {props.isTelephonic ? <TelephonicAttestation /> : <AgreementClause />}
          <FormValidationSummary errors={errors} touched={touched} />
          <SignatureBlock isSubmitting={isSubmitting} isTelephonic={props.isTelephonic} />
          {isSubmitting && <LoadingOverlay text="Submitting..." />}
        </Form>
      )}
    </Formik>
  );
};

export default BeneficiaryForm;
