import React, { Fragment } from 'react';
import GitInfo from 'react-git-info/macro';

import FullPageMessage from './common/full-page-message';
import { WindowCornerText } from './common/window-corner-text';

export const Home = () => {
  const gitInfo = GitInfo();

  return (
    <Fragment>
      <FullPageMessage title="Guided Solutions" subtitle="Scope of Appointments">
        <p>
          This is the Guided Solutions Scope of Appointment service. There is
          nothing to do on this page.
        </p>
        <p>
          If you reached this page from a request to sign a Scope of Appointment
          please try following the link in the original communication again to
          open the document for signing.
        </p>
      </FullPageMessage>
      <WindowCornerText>build {gitInfo.commit.shortHash}</WindowCornerText>
    </Fragment>
  );
};
