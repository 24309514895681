import { format } from 'date-fns';

export const addSignatureTimestamp = (values: Record<string, unknown>) => {
  const date = new Date();
  // get a JSON friendly datestring for the users local time when this was signed
  //const tzTimestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
  return {
    ...values,
    signedDate: format(date, 'yyyy-MM-dd'),
    // include the local timezone of the signer (from the browser) so the signature timestamp
    // can be localized
    signedDateTimeZone: getTimeZoneName(),
  };
};

const getTimeZoneName = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // if browser doesn't support the necessary timezone retrieval just return empty string
    // as it will be treated as UTC
    return '';
  }
}
