import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { ConnectedInput } from './connected-input';
import SubmitButton from './submit-button';
import ConditionalSignatureBlockHeader from './conditional-signature-block-header';

const SignatureBlock = (props: { isSubmitting: boolean, isTelephonic?: boolean }) => (
  <Container>
    <Row>
      <ConditionalSignatureBlockHeader isTelephonic={props.isTelephonic} />
    </Row>
    <Row>
      <Col md="auto" xs="auto">
        <ConnectedInput
          type="text"
          name="signedName"
          placeholder="Enter full name here"
        />
      </Col>
      <Col md="auto" xs="auto">
        <div className="float-right">
          <SubmitButton
            submitting={props.isSubmitting}
            title="I Agree"
            color="success"
          />
        </div>
      </Col>
    </Row>
  </Container>
);

export default SignatureBlock;
