import React from 'react';
import { Col, Container, Row } from 'reactstrap';

/**
 * Full page simple message - branded with Guided logo
 */
const FullPageMessage = (props: {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}) => (
  <Container className="text-center full-page-message">
    <Row>
      <Col md={12}>
        <img
          className="logo"
          src="/images/guided_logo.png"
          alt="Guided Medicare Solutions Logo"
          width={600}
        ></img>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <h1>{props.title}</h1>
        {props.subtitle && <h2>{props.subtitle}</h2>}
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <div className="message-body justify-content-center">
          {props.children}
        </div>
      </Col>
    </Row>
  </Container>
);

export default FullPageMessage;
