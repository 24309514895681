import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';

import RequestStartForm from './form';

import { RequestFormValuesWithCaptcha } from 'api/request-start';
import BrandingLogo from 'components/common/branding-logo';
import SpinnerText from 'components/common/spinner-text';
import { ErrorDisplay } from 'components/common/error-display';
import { useProfile } from 'helpers/hooks/profile';
import { useStartSigningRequest } from 'helpers/hooks/signing-request';

export const RequestStart = () => {
  const { profileId } = useParams<'profileId'>();

  const {
    isLoading,
    error: profileError,
    imageUrl,
  } = useProfile(profileId || 'unknown');
  const { startSigningRequest } = useStartSigningRequest(
    profileId || 'unknown'
  );
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, 'RequestStart');

  const startSigningSession = async (values: RequestFormValuesWithCaptcha) => {
    trackComponent();
    const response = await startSigningRequest(values);
    navigate(
      `/sign-now/${response.data.requestId}/customer?token=${response.data.signingSessionToken}`
    );
  };

  if (isLoading) return <SpinnerText text="Loading" />;
  if (profileError) return <ErrorDisplay text={profileError} />;

  return (
    <Container className="text-center">
      <Row className="align-items-center justify-content-center">
        <Col md={12}>
          {imageUrl && <BrandingLogo imageUrl={imageUrl} />}
          <h5>Start a Scope of Appointment</h5>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center">
        <Col md={3} sm={4}>
          <RequestStartForm
            onSubmit={async (values) => await startSigningSession(values)}
          />
        </Col>
      </Row>
    </Container>
  );
};
