import React from 'react';
import { Button, Spinner } from 'reactstrap';

const SubmitButton = (props: {
  title: string;
  submitting: boolean;
  color?: string;
  submittingText?: string;
}) => (
  <Button
    type="submit"
    color={props.color || 'primary'}
    disabled={props.submitting}
  >
    {props.submitting ? (
      <span className="align-middle">
        Submitting&nbsp;&nbsp;
        <Spinner size="sm" />
      </span>
    ) : (
      props.title
    )}
  </Button>
);

export default SubmitButton;
