import { PlanTypeSelections } from 'models/beneficiary';

export type PlanTypeDetails = {
  header: string;
  sections: {
    title?: string;
    body: string;
  }[];
};

export type PlanTypeInfoData = {
  name: string;
  details?: PlanTypeDetails;
};

export type PlanType = keyof PlanTypeSelections;

export const PlanTypeData: {
  [planType in PlanType]: PlanTypeInfoData;
} = {
  pdp: {
    name: 'Stand-alone Medicare Prescription Drug Plans',
    details: {
      header: 'Stand-alone Medicare Prescription Drug Plans (Part D)',
      sections: [
        {
          title: 'Medicare Prescription Drug Plan (PDP)',
          body: 'A stand-alone drug plan that adds prescription drug coverage to Original Medicare, some Medicare Cost Plans, some Medicare Private-Fee-for-Service Plans, and Medicare Medical Savings Account Plans.',
        },
      ],
    },
  },
  ma: {
    name: 'Medicare Advantage Plans',
    details: {
      header: 'Medicare Advantage Plans (Part C) and Cost Plans',
      sections: [
        {
          title: 'Medicare Health Maintenance Organization (HMO)',
          body: 'A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage and sometimes covers Part D prescription drug coverage. In most HMOs, you can only get your care from doctors or hospitals in the plan’s network (except in emergencies).',
        },
        {
          title: 'Medicare Preferred Provider Organization (PPO) Plan',
          body: 'A Medicare Advantage Plan that provides all Original Medicare Part A and Part B health coverage and sometimes covers Part D prescription drug coverage. PPOs have network doctors and hospitals but you can also use out-of-network providers, usually at a higher cost.',
        },
        {
          title: 'Medicare Point of Service (HMO-POS) Plan',
          body: 'A type of Medicare Advantage Plan available in a local or regional area which combines the best feature of an HMO with an out-of-network benefit. Like the HMO, members are required to designate an in-network physician to be the primary health care provider. You can use doctors, hospitals, and providers outside of the network for an additional cost.',
        },
        {
          title: 'Medicare Special Needs Plan (SNP)',
          body: 'A Medicare Advantage Plan that has a benefit package designed for people with special health care needs. Examples of the specific groups served include people who have both Medicare and Medicaid, people who reside in nursing homes, and people who have certain chronic medical conditions.',
        },
      ],
    },
  },
  dentalVisionHearing: {
    name: 'Dental / Vision / Hearing Products',
    details: {
      header: 'Dental/Vision/Hearing Products',
      sections: [
        {
          body: 'Plans offering additional benefits for consumers who are looking to cover needs for dental, vision or hearing. These plans are not affiliated or connected to Medicare.',
        },
      ],
    },
  },
  supplementalHealth: {
    name: 'Supplemental Health Products',
    details: {
      header: 'Supplemental Health Products',
      sections: [
        {
          body: 'Plans offering additional benefits; payable to consumers based upon their medical utilization; sometimes used to defray copays/coinsurance. These plans are not affiliated or connected to Medicare.',
        },
      ],
    },
  },
  ms: {
    name: 'Medicare Supplement (Medigap) Products',
    details: {
      header: 'Medicare Supplement (Medigap) Products',
      sections: [
        {
          body: 'Plans offering a supplemental policy to fill “gaps” in Original Medicare coverage. A Medigap policy typically pays some or all of the deductible and coinsurance amounts applicable to Medicare-covered services, and sometimes covers items and services that are not covered by Medicare, such as care outside of the country. These plans are not affiliated or connected to Medicare.',
        },
      ],
    },
  },
  aca: {
    name: 'Individual & Family Health Insurance (ACA) Products',
    details: {
      header: 'Individual & Family Health Insurance (ACA) Products',
      sections: [
        {
          body: 'Individual & Family Health Insurance (ACA) plans are designed to give you basic health benefits. Depending on your specific situation and family status, you could be eligible for tax credits to assist you with offsetting the costs of your health insurance plan.',
        },
      ],
    },
  },
};
