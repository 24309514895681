import React, { CSSProperties } from 'react';
import InputMask from 'react-input-mask';

import { ConnectedInputGroup } from 'components/common/connected-input-group';
import { formatPhoneNumber } from 'helpers/phone-number-format';

interface EventState {
  value: string;
}

const beforeMaskedStateChange = (e: {
  nextState: EventState;
  currentState: EventState;
  previousState?: EventState;
}) => {
  const { nextState, currentState, previousState } = e;
  // previousState is only defined for change events
  // so we only want to modify the nextState on change events
  if (!previousState) return nextState;

  let { value } = nextState;
  const { value: currentValue } = currentState;

  // try formatting the current value as a phone number and see if we end
  // up with a different value. If so, we should use that
  const formattedNumber = formatPhoneNumber(currentValue);
  if (formattedNumber && formattedNumber !== currentValue) {
    value = formattedNumber;
  }

  return {
    ...nextState,
    value,
  };
};

const ConnectedPhoneInput = (props: {
  name: string;
  label: string;
  groupClassName?: string;
  groupStyle?: CSSProperties | undefined;
}) => (
  <ConnectedInputGroup
    type="tel"
    name={props.name}
    label={props.label}
    mask="(999) 999-9999"
    // event handler intercepts updates to prevent overwriting autofilled
    // phone numbers from the browser
    beforeMaskedStateChange={beforeMaskedStateChange}
    tag={InputMask}
    groupClassName={props.groupClassName}
    groupStyle={props.groupStyle}
  />
);

export default ConnectedPhoneInput;
