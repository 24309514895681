import { BeneficiaryResponses } from "models/beneficiary";

/**
 * Update form values with contact info copied from beneficiary to authorized representative
 * @param values Form values
 * @returns Updated form values with contact info for the beneficiary copied into the authorized rep
 */
export function updateValuesWithContactFromBeneficiary(values: BeneficiaryResponses): BeneficiaryResponses {
    // copy all fields except the name
    const { firstName: _, lastName: __, ...objectToCopy } = values.beneficiary;

    return Object.assign(values, {
        authorizedRepresentative: Object.assign(values.authorizedRepresentative, objectToCopy)
    } as Partial<BeneficiaryResponses>);
}