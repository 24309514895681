import React, { Fragment, useState } from 'react';
import { Collapse, Card, CardBody, Col, Row } from 'reactstrap';
import { FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa';

import { ConnectedYesNoSwitch } from 'components/common/connected-yes-no-switch';
import {
  PlanTypeData,
  PlanTypeDetails,
  PlanTypeInfoData,
} from 'components/common/plan-type-data';

const PlanTypeDetailsPanel = (props: PlanTypeDetails) => (
  <div>
    <h5>{props.header}</h5>
    {props.sections.map((s, idx) => (
      <div key={idx}>
        {s.title && (
          <span>
            <strong>{s.title}</strong> --{' '}
          </span>
        )}
        {s.body}
      </div>
    ))}
  </div>
);

const PlanTypeRow = (props: {
  fieldName: string;
  planTypeInfo: PlanTypeInfoData;
  expanded?: boolean;
  onPressHelp?: () => void;
}) => {
  const isOpen = props.expanded === true;
  return (
    <Fragment>
      <Row
        className="plan-type-row align-items-center"
        key={props.planTypeInfo.name}
      >
        <Col>{props.planTypeInfo.name}</Col>
        <Col xs="auto" className="plan-type-help-column">
          {props.planTypeInfo.details && (
            <span
              onClick={props.onPressHelp}
              title="Click to see a description of the product type"
              className="plan-type-help-icon"
            >
              {isOpen ? (
                <FaQuestionCircle size={30} />
              ) : (
                <FaRegQuestionCircle size={30} />
              )}
            </span>
          )}
        </Col>
        <Col xs="auto" className="plan-type-response-column">
          <div className="float-right">
            <ConnectedYesNoSwitch name={props.fieldName} />
          </div>
        </Col>
      </Row>

      {props.planTypeInfo.details && (
        <Row>
          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <PlanTypeDetailsPanel {...props.planTypeInfo.details} />
              </CardBody>
            </Card>
          </Collapse>
        </Row>
      )}
    </Fragment>
  );
};

const PlanTypeSelections = (props: { namePrefix: string }) => {
  const [currentlyOpen, setCurrentlyOpen] = useState<string | undefined>();

  const rowData = [
    {
      field: 'pdp',
      planTypeInfo: PlanTypeData.pdp,
    },
    {
      field: 'ma',
      planTypeInfo: PlanTypeData.ma,
    },
    {
      field: 'dentalVisionHearing',
      planTypeInfo: PlanTypeData.dentalVisionHearing,
    },
    {
      field: 'supplementalHealth',
      planTypeInfo: PlanTypeData.supplementalHealth,
    },
    {
      field: 'ms',
      planTypeInfo: PlanTypeData.ms,
    },
    {
      field: 'aca',
      planTypeInfo: PlanTypeData.aca,
    },
  ];

  const helpPressed = (name: string) => {
    if (currentlyOpen === name) {
      // clear selection to close the help panel
      setCurrentlyOpen(undefined);
    } else {
      // open selection
      setCurrentlyOpen(name);
    }
  };

  return (
    <Fragment>
      {rowData.map((p, idx) => (
        <Fragment key={idx}>
          <PlanTypeRow
            fieldName={`${props.namePrefix}${p.field}`}
            planTypeInfo={p.planTypeInfo}
            expanded={currentlyOpen === p.field}
            onPressHelp={() => helpPressed(p.field)}
          />
          {/* last row doesn't get a horizontal separator line below it as we only want it between rows */}
          {idx < rowData.length - 1 && <hr />}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PlanTypeSelections;
