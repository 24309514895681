import { BsExclamationCircle } from "react-icons/bs";
import { FormGroup, Input, InputProps, Label } from "reactstrap";

const ValidatedCheckbox = (props: {
    invalid?: boolean;
    label?: React.ReactNode;
} & Omit<InputProps, "type">) => {
    const className = props.invalid === true ? 'is-invalid' : '';

    return (
        <FormGroup check inline>
            <Input {...props} type="checkbox" className={className} />
            {props.label && <Label>
                {props.label}
            </Label>}
            <BsExclamationCircle
                size="1.5em"
                display={props.invalid ? '' : 'none'}
                className="invalid-input-icon"
            />
        </FormGroup>
    );
}

export default ValidatedCheckbox;