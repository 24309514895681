import React from 'react';
import { Col, Label, Row } from 'reactstrap';

import { ConnectedYesNoSwitch } from './connected-yes-no-switch';

const ConditionalQuestionRow = (props: { title: string; name: string }) => {
  return (
    <Row>
      <Col md={8}>
        <Label>{props.title}</Label>
      </Col>
      <Col md={4}>
        <ConnectedYesNoSwitch name={props.name} />
      </Col>
    </Row>
  );
};

export default ConditionalQuestionRow;
