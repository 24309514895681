import React from 'react';
import { BsClipboardPlus } from 'react-icons/bs';
import { Button } from "reactstrap";

/**
 * Button for copying contact info from the beneficiary
 * @param props component props
 * @returns copy beneficiary contact info button component
 */
const CopyBeneficiaryContactButton = (props: {
    onClick(): void;
}) => <Button onClick={props.onClick} size="sm">
        <BsClipboardPlus size="1.5em" /> Use beneficiary contact information
    </Button>;

export default CopyBeneficiaryContactButton;