import TelephonicAlert from './telephonic-alert';
import TelephonicIntroParagraph from './telephonic-intro-script';
import IntroParagraph from './intro-paragraph';
import { Container } from 'reactstrap';

const ConditionalIntroBlock = (props: { isTelephonic: boolean }) => {
    return (
        <Container>
            {!props.isTelephonic ? (
                <IntroParagraph />
            ) : (
                <div>
                    <TelephonicAlert />
                    <TelephonicIntroParagraph />
                </div>
            )}
        </Container>
    );
};

export default ConditionalIntroBlock;