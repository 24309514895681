import React from 'react';
import { Alert } from 'reactstrap';

const TelephonicAlert = () => (
    <Alert color="warning">
        <p className="mb-0">Please complete the form with the beneficiary's responses.</p>
        <p className="mb-1">*The blue boxes below are <strong>REQUIRED</strong> scripts to read for compliance with telephonic SOAs.</p>
    </Alert>
);

export default TelephonicAlert;
