
import { Col, Row } from 'reactstrap';
import ConnectedValidatedCheckbox from 'components/common/connected-validated-checkbox';
import TelephonicScript from './telephonic-script';

const TelephonicAttestation = () => {
    return (
        <Row className='align-items-center'>
            <Col md={8}>
                <TelephonicScript>
                    <p>By completing this telephonic Scope of Sales Confirmation today, you confirm that you agree to a meeting with one of our sales agents to discuss the type of products you replied yes to.</p>
                    <p>Do you agree?</p>
                </TelephonicScript>
            </Col>
            <Col md={4}>
                <ConnectedValidatedCheckbox name="telephonicScopeConfirmation" label={<p>Yes, I agree</p>} />
            </Col>
        </Row>
    )
};

export default TelephonicAttestation;
