import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import GitInfo from 'react-git-info/macro';

/**
 * Adds Meta tags that will get moved into the HTML <head> tag
 */
const GitInfoMetadata = (props: {
  children?: React.ReactNode;
}): JSX.Element => {
  const gitInfo = GitInfo();

  return (
    <Fragment>
      <Helmet>
        <meta name="ui-version" content={gitInfo.commit.shortHash} />
      </Helmet>
      {props.children}
    </Fragment>
  );
};

export default GitInfoMetadata;
