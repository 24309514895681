import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import SpinnerText from './components/common/spinner-text';
import { Home } from './components/home';
import { NotFound } from './components/not-found';
import { RequestStart } from './components/request-start';
import { SignNow } from './components/sign-now';

import './custom.css';
import 'react-toastify/dist/ReactToastify.css';

// lazy load these areas to enable code splitting in webpack
// see: https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const AdminArea = lazy(() => import('./components/admin'));
const DemoArea = lazy(() => import('./components/demo'));
const LazyLoadingFallback = <SpinnerText text="Loading..." />;

export default class App extends Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <Fragment>
        <ToastContainer />
        <Suspense fallback={LazyLoadingFallback}>
          <Routes>
            <Route
              path="/request-start/:profileId"
              element={<RequestStart />}
            />
            <Route path="/sign-now/:requestId/:role" element={<SignNow />} />
            <Route path="/admin/*" element={<AdminArea />} />
            <Route path="/demo/*" element={<DemoArea />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Fragment>
    );
  }
}
