import React from 'react';

import FullPageMessage from './full-page-message';

export const ErrorDisplay = (props: { title?: string; text: string }) => {
  return (
    <FullPageMessage title={props.title || 'An error occured'}>
      <p>{props.text}</p>
    </FullPageMessage>
  );
};
