import React from 'react';

import { ConnectedInputGroup } from 'components/common/connected-input-group';
import ConnectedPhoneInput from 'components/common/connected-phone-input';

const AgentInfo = () => (
  <div>
    <ConnectedInputGroup type="text" name="firstName" label="First Name" />
    <ConnectedInputGroup type="text" name="lastName" label="Last Name" />
    <ConnectedPhoneInput
      name="phoneNumber"
      label="Phone Number"
      groupClassName="w-25"
      groupStyle={{
        minWidth: '150px'
      }}
    />
  </div>
);

export default AgentInfo;
