import React from 'react';
import { Field, FieldProps } from 'formik';

import YesNoSwitch from './yes-no-switch';

export const ConnectedYesNoSwitch = (props: { name: string }) => (
  <Field name={props.name}>
    {({
      field,
      form: { setFieldValue },
      meta,
    }: FieldProps<boolean | undefined>) => (
      <YesNoSwitch
        value={field.value}
        onChange={(newVal) => setFieldValue(field.name, newVal)}
        invalid={meta.touched && !!meta.error}
      />
    )}
  </Field>
);
