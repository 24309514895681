import React, { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';

import { AgentView } from 'components/agent';
import { BeneficiaryView } from 'components/beneficiary';
import { ErrorDisplay } from 'components/common/error-display';
import SpinnerText from 'components/common/spinner-text';
import { addSignatureTimestamp } from 'helpers/add-signature-timestamp';
import { CompletedScreen } from './completed';
import { AgentResponses } from 'models/agent';
import { BeneficiaryResponses } from 'models/beneficiary';
import { RecursivePartial } from 'models/common';

import { useSigningSession } from 'services/api/signing-session';
import { useState } from 'react';

export interface SignNowRouteParameters {
  requestId: string;
  role: string;
}

export const SignNow = () => {
  const { requestId, role } = useParams<'requestId' | 'role'>();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, 'SignNow');
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  // open signing session
  const {
    isLoading,
    error: errorText,
    contextData,
    prefillData,
    submitFormResponses,
    imageUrl,
  } = useSigningSession(
    requestId || 'unknown',
    role || 'unknown',
    token as string
  );

  if (isLoading) return <SpinnerText text="Loading" />;
  if (errorText) return <ErrorDisplay text={errorText} />;

  // once the signature was submitted we want to show a completion screen
  if (isComplete) {
    return <CompletedScreen logoUrl={imageUrl} redirecting={isRedirecting} />;
  }

  // wrap up submission event handler so we can trigger app insights
  const trackedSubmitFormResponses = async (data: Record<string, unknown>) => {
    trackComponent();

    // add in timestamp of signature
    const values = addSignatureTimestamp(data);

    try {
      const returnUrl = await submitFormResponses(values);
      setIsComplete(true);

      if (returnUrl && returnUrl.length) {
        // if signing session is embedded and configured with a return url
        // redirect there
        setIsRedirecting(true);
        window.location.href = returnUrl;
      } else {
        // on mobile the user may be scrolled a ways down and not see the completion
        // page message up at the top.
        window.scrollTo(0, 0);
      }
    } catch (e) {
      appInsights.trackException({ exception: e as Error });
      console.error(e);
      toast.error('An error occured while submitting the responses.');
    }
  };

  if (role === 'customer') {
    return (
      <BeneficiaryView
        onSubmit={async (data: BeneficiaryResponses) =>
          await trackedSubmitFormResponses(data)
        }
        initialValues={
          (prefillData as RecursivePartial<BeneficiaryResponses>) || {}
        }
        logoUrl={imageUrl}
        isTelephonic={!!contextData?.isTelephonic}
      />
    );
  } else if (role === 'agent') {
    const prefillAgentData = {
      ...prefillData,
    } as RecursivePartial<AgentResponses>;
    if (prefillData && prefillData.appointmentDate && typeof prefillData.appointmentDate === 'string') {
      prefillAgentData.appointmentDate = new Date(prefillData.appointmentDate as string);
    }

    return (
      <AgentView
        onSubmit={async (data: AgentResponses) =>
          await trackedSubmitFormResponses(data)
        }
        beneficiaryContext={contextData.beneficiaryContext as Record<string, unknown> || {}}
        initialValues={prefillAgentData}
        logoUrl={imageUrl}
      />
    );
  } else {
    return <ErrorDisplay text="Unrecognized signing role" />;
  }
};
