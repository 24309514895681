import React from 'react';

import SpinnerText from './spinner-text';

const LoadingOverlay = (props: { text: string }) => {
  return (
    <div className="loading-overlay">
      <SpinnerText text={props.text} />
    </div>
  );
};

export default LoadingOverlay;
