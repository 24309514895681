import axios, { AxiosResponse } from 'axios';

import { RequestCreateResponse } from 'api/types';
import { RequestFormValuesWithCaptcha } from 'api/request-start';

export const useStartSigningRequest = (
  profileId: string
): {
  startSigningRequest: (
    values: RequestFormValuesWithCaptcha
  ) => Promise<AxiosResponse<RequestCreateResponse>>;
} => {
  const startSigningRequest = async (values: RequestFormValuesWithCaptcha) => {
    return await axios.post<RequestCreateResponse>('api/request', {
      profileId,
      ...values,
    });
  };

  return {
    startSigningRequest,
  };
};
