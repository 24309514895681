import React from 'react';
import { Card, CardHeader, Container } from 'reactstrap';

export const SoaFormCard = (props: {
  title: string;
  children: React.ReactNode;
}) => (
  <Card>
    <CardHeader>
      <h6>{props.title}</h6>
    </CardHeader>
    <Container>{props.children}</Container>
  </Card>
);
